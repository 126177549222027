import Link from 'next/link'

import FooterContacts from '../footer-contacts/footer-contacts'
import FooterLogo from '../footer-logo/footer-logo'

import { legalInfo, aboutUs, qualification } from '../data'

import styles from './footer-desktop.module.scss'

const FooterDesktop = () => {
  return (
    <div className={styles.container}>
      <div>
        <FooterLogo />
        <FooterContacts />
      </div>
      <div className={styles.secondBlock}>
        <div>
          <p className={styles.listTitle}>Юридическая информация</p>
          <ul className={styles.listItems}>
            {legalInfo.map((item, index) => (
              <Link href={item.href} key={index}>
                <li>{item.text}</li>
              </Link>
            ))}
          </ul>
        </div>
      </div>
      <div className={styles.thirdBlock}>
        <div>
          <p className={styles.listTitle}>повышение квалификации (нмо)</p>
          <ul className={styles.listItems}>
            {qualification.map((item, index) => (
              <Link href={item.href} key={index}>
                <li>{item.text}</li>
              </Link>
            ))}
          </ul>
        </div>
        <div>
          <p className={styles.listTitle}>о проекте</p>
          <ul className={styles.listItems}>
            {aboutUs.map((item, index) => (
              <Link href={item.href} key={index} rel={item.nofollow}>
                <li>{item.text}</li>
              </Link>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default FooterDesktop
