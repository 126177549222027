import MaxWidthLimiter from 'containers/max-width-limiter/max-width-limiter'
import FooterDesktop from './footer-desktop/footer-desktop'
import FooterMobile from './footer-mobile/footer-mobile'
import usePCWindow from 'hooks/usePCWindow'

import { ScreenSize } from 'constants/media-constanst'

import styles from './footer.module.scss'

const Footer = () => {
  const isStandartPC = usePCWindow(ScreenSize.standart)
  return (
    <footer className={styles.footer}>
      <MaxWidthLimiter noTopPadding noBottomPadding noLeftPadding noRightPadding>
        <div className={styles.footerWrapper}>
          {isStandartPC ? <FooterDesktop /> : <FooterMobile />}
        </div>
      </MaxWidthLimiter>
    </footer>
  )
}

export default Footer
