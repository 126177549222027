import clsx from 'clsx'
import Image from 'next/image'
import Link from 'next/link'

import { imgPath, partnerLinks } from '../data'

import styles from './footer-contacts.module.scss'

const FooterContacts = () => (
  <div className={styles.contacts}>
    <a href="tel:+74992261847" className={styles.phone}>
      <Image
        src={imgPath.phone}
        className={styles.mailIcon}
        alt="phone icon"
        width="14"
        height="12"
      />
      <span>+7 (499) 226-18-47</span>
    </a>
    <a href="mailto:info@medpoint.pro" className={styles.mail}>
      <Image
        src={imgPath.mail}
        className={styles.mailIcon}
        width="14"
        height="12"
        alt="mail icon"
      />
      <span>info@medpoint.pro</span>
    </a>
    <div className={styles.partnersWrapper}>
      {partnerLinks.map(({ href, logo, className, alt, width, height }) => (
        <Link
          key={alt}
          href={href}
          target="_blank"
          rel="noreferrer"
          className={clsx(styles.icon, { [styles.noEvents]: !href })}
        >
          <div className={styles.iconWrapper}>
            <Image src={logo} className={className} alt={alt} width={width} height={height} />
          </div>
        </Link>
      ))}
    </div>
    <p className={styles.projectText}>
      Вся размещенная информация на портале является объектом авторского права и запрещена к
      копированию без согласия авторов. 2019-{new Date().getFullYear()} © Все права защищены.
    </p>
  </div>
)

export default FooterContacts
