import { FC, ReactNode } from 'react'
import clsx from 'clsx'

import styles from './max-width-limiter.module.scss'

interface IMaxWidthLimiter {
  children: ReactNode
  noTopPadding?: boolean
  noBottomPadding?: boolean
  noLeftPadding?: boolean
  noRightPadding?: boolean
  modificator?: string
}

const MaxWidthLimiter: FC<IMaxWidthLimiter> = ({
  noTopPadding,
  noBottomPadding,
  noLeftPadding,
  noRightPadding,
  modificator,
  children,
}: IMaxWidthLimiter) => (
  <div
    className={clsx(styles.main, {
      [styles.noTopPadding]: noTopPadding,
      [styles.noBottomPadding]: noBottomPadding,
      [styles.noLeftPadding]: noLeftPadding,
      [styles.noRightPadding]: noRightPadding,
      [modificator as string]: modificator,
    })}
  >
    {children}
  </div>
)

export default MaxWidthLimiter
