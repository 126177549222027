import Link from 'next/link'
import clsx from 'clsx'

import FooterLogo from '../footer-logo/footer-logo'
import FooterContacts from '../footer-contacts/footer-contacts'
import useToggle from 'hooks/useToggle'

import { legalInfo, qualification, aboutUs } from '../data'

import styles from './footer-mobile.module.scss'

interface IFooterMobile {
  modificator?: string
}

const FooterMobile = () => {
  const [isOpenLegal, setIsOpenLegal] = useToggle()
  const [isOpenAbout, setIsOpenAbout] = useToggle()
  const [isOpenQual, setIsOpenQual] = useToggle()
  return (
    <div>
      <FooterLogo />
      <div className={clsx(styles.list, { [styles.animArrow]: isOpenLegal })}>
        <p className={styles.listTitle} onClick={setIsOpenLegal}>
          Юридическая информация
        </p>
        <ul className={clsx(styles.listItems, { [styles.open]: isOpenLegal })}>
          {legalInfo.map((item, index) => (
            <Link href={item.href} key={index}>
              <li>{item.text}</li>
            </Link>
          ))}
        </ul>
      </div>
      <div className={clsx(styles.list, { [styles.animArrow]: isOpenAbout })}>
        <p className={styles.listTitle} onClick={setIsOpenAbout}>
          о проекте
        </p>
        <ul className={clsx(styles.listItems, { [styles.open]: isOpenAbout })}>
          {aboutUs.map((item, index) => (
            <Link href={item.href} key={index} rel={item.nofollow}>
              <li>{item.text}</li>
            </Link>
          ))}
        </ul>
      </div>
      <div className={clsx(styles.list, { [styles.animArrow]: isOpenQual })}>
        <p className={styles.listTitle} onClick={setIsOpenQual}>
          повышение квалификации (нмо)
        </p>
        <ul className={clsx(styles.listItems, { [styles.open]: isOpenQual })}>
          {qualification.map((item, index) => (
            <Link href={item.href} key={index}>
              <li>{item.text}</li>
            </Link>
          ))}
        </ul>
      </div>
      <FooterContacts />
    </div>
  )
}

export default FooterMobile
